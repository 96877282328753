/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, MaterializeForm) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $contactForm = $('.contact-form')
        ;

        /*
		|
		| Materialize form
		|-------------------
		|
        */
        new MaterializeForm($contactForm, {
            selectors: {
                group: '.gfield',
                label: '.gfield_label',
                input: '.ginput_container_text input, .ginput_container_email input, .ginput_container_number input'
            },
            labelEffect: {
                duration: 0.3,
                ease: Power2.easeOut
            }
        });

    }
}
