/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, CookieManager, Sticky, InfiniteAjaxScroll, simpleParallax, NiceSelect, Fancybox) => {

		/*
		|
		| Constants
		|-----------
		*/
        const 
            $body         = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose  = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
		;


        /*
		|
		| Cookie Manager
		|-----------------
		*/
        // new CookieManager($cookieBanner, {
        //     name: 'agence2web_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     }
        // });

        /*
		|
		| Sticky Menu
		|-----------------
		*/
        $("#header").sticky({topSpacing:0});

        /*
		|
		| Infinite Ajax Scroll - Actualités / Avis
		|-----------------
		*/
        if ($('.posts').length) {
            let ias = new InfiniteAjaxScroll('.posts', {
                item: '.post',
                next: '.next',
                pagination: '#pagination',
                spinner: '.spinner',
                logger: false,
                loadOnScroll: true
            });
            ias.on('appended', handler);
        }


        function handler(event) {
            // Parallax images
            var images = document.querySelectorAll('.parallax');
            new simpleParallax(images, {
                delay: 0,
                orientation: 'down',
                scale: 1.3,
                overfow: false,
            });
        }

        /*
		|
		| Select custom
		|-----------------
		*/
        $('select.filter-form, .filter-form select').niceSelect();

        /*
		|
		| Fancybox
		|-----------------
		*/
        $('[data-fancybox="gallery"]').fancybox({
            loop: true,
            infobar: false,
            buttons: [
                "close"
            ]
        });
        $('[data-fancybox="video"]').fancybox({
            youtube : {
                controls : 0,
                showinfo : 0
            }
        });

        $('[data-fancybox="iframe"]').fancybox({
            toolbar  : false,
            smallBtn : true,
            iframe : {
                preload : false
            }
        })

        /* Agences hover */
        $('.group-agencies-bloc-image a').on('mouseenter', function(e) {
            e.preventDefault();
            $(this).parent().next().addClass('active');
        });

        $('.group-agencies-bloc-image a').on('mouseleave', function(e) {
            e.preventDefault();
            $(this).parent().next().removeClass('active');
        });

        $('#filter-element').on('change', function() {
            location.href = $(this).val();
        });

        /*
		|
		| Contact bien
		|-----------------
		*/
        if ($('.form-bien').length > 0) {
            let bienLink = $('.form-bien').data('link');
            if (bienLink != null) {
                $('.contact #input_1_7').attr('value', bienLink);
                $('#input_8_6').attr('value', bienLink);
            }
        }

        /**
         * Select 2
         * * */
        if ($('.select2-multiple').length > 0) {
            var $select = $('.select2-multiple').selectize({
                plugins: ['remove_button']
            });

            var selectize = $select[0].selectize;

            selectize.on('item_add', function(value) {
                var cityParamsI = value.replace(' ', '_');
                $('.map-biens #'+cityParamsI).addClass('active');
            });

            selectize.on('item_remove', function(value) {
                var cityParamsI = value.replace(' ', '_');
                $('.map-biens #'+cityParamsI).removeClass('active');
            });
        }

        /*
		|
		| MAP
		|-----------------
		*/
        if ($('.map-biens').length > 0) {
            $('.path').on('click', function() {
                var myId = ($(this).attr('id')).replace('_', ' ');

                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    selectize.removeItem(myId);
                } else {
                    $(this).addClass('active');
                    selectize.addItem(myId);
                }
            });

            if (cityParams != 'undefined' && cityParams != '') {
                $.each(cityParams, function(i, n) {
                    var cityParamsI = n.replace(' ', '_');
                    $('.map-biens #'+cityParamsI).addClass('active');
                });
            }
        }



	}
}
